<template>
  <div class="w-24 mx-auto mt-14">
    <LottieAnimation :options="lottieOptions" @animCreated="handleAnimation" />
  </div>
</template>

<script>
import LottieAnimation from 'vue-lottie/src/lottie.vue'
import * as animationData from '@/assets/lottieFiles/loading-spinner.json'

export default {
  name: 'LoadingSpinner',
  components: { LottieAnimation },
  data() {
    return {
      anim: null, // for saving the reference to the animation
      lottieOptions: { animationData: animationData.default },
    }
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim
    },
  },
}
</script>
